import { Api } from "@/models/class/api.class";
import { RequestBatchCreate, RequestBatchSupplierCreate, ResponseBatch, ResponseBatchCreate, ResponseListBatch } from "@interface/batch.interface";
import { ErrorResponseModel, RequestQueryParamsModel } from "@/models/interface/http.interface";
import { HttpClient } from "@/services/http.service";
import { AxiosError } from "axios";

export class BatchService extends HttpClient {
  constructor() {
    super();

    this.listBatch = this.listBatch.bind(this);
    this.createBatch = this.createBatch.bind(this);
    this.viewBatch = this.viewBatch.bind(this);
    this.updateBatch = this.updateBatch.bind(this);
    this.findByBarcode = this.findByBarcode.bind(this);
  }

  /**
   * view all created batch
   * @param params query params
   * @returns list created batch
   */
  listBatch(params: RequestQueryParamsModel): Promise<ResponseListBatch> {
    return this.get<ResponseListBatch>(Api.Batch, { params })
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  /**
   * create new batch
   * @param payload request batch
   * @returns http response
   */
  createBatch(payload: RequestBatchCreate): Promise<ResponseBatchCreate> {
    return this.post<ResponseBatchCreate, RequestBatchCreate>(Api.Batch, payload)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  /**
   * view batch by id
   * @param batchId batch id
   * @returns batch
   */
  viewBatch(batchId: string): Promise<ResponseBatch> {
    return this.get<ResponseBatch>(`${Api.Batch}/${batchId}`)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  /**
   * update existing batch
   * @param payload request batch
   * @returns http response
   */
   updateBatch(batchId: string, payload: RequestBatchCreate): Promise<ResponseBatchCreate> {
    return this.put<ResponseBatchCreate, RequestBatchCreate>(`${Api.Batch}/${batchId}`, payload)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  /**
   * find product by barcode
   * @param barcode barcode number
   * @returns batch product
   */
  findByBarcode(barcode: string): Promise<ResponseBatch> {
    return this.post<ResponseBatch, null>(`${Api.Batch}/find/${barcode}`, null)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  downloadBatch(params: RequestQueryParamsModel): Promise<ArrayBuffer> {
    return this.get<ArrayBuffer>(`${Api.Batch}/report/download`, { params, responseType: "arraybuffer" })
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  findByBatchNumber(batchNumber: string): Promise<ResponseBatch> {
    return this.get<ResponseBatch>(`${Api.Batch}/find/${batchNumber}`)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  findBatchInventory(batchNumber: string): Promise<ResponseBatch> {
    return this.get<ResponseBatch>(`${Api.Batch}/find-inventory/${batchNumber}`)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  getPrintFile(batchNumber: string): Promise<ArrayBuffer> {
    return this.get<ArrayBuffer>(`${Api.Batch}/qr-code`, {params: { batchNumber }, responseType: "arraybuffer"})
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  /**
   * create new batch with supplier
   * @param payload request batch
   * @returns http response
   */
   createBatchSupplier(payload: RequestBatchSupplierCreate): Promise<ResponseBatchCreate> {
    return this.post<ResponseBatchCreate, RequestBatchSupplierCreate>(`${Api.Batch}/supplier`, payload)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  /**
   * update existing batch supplier
   * @param payload request batch
   * @returns http response
   */
   updateBatchSupplier(batchId: string, payload: RequestBatchSupplierCreate): Promise<ResponseBatchCreate> {
    return this.put<ResponseBatchCreate, RequestBatchSupplierCreate>(`${Api.Batch}/supplier/${batchId}`, payload)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }
}

export const batchService = new BatchService();
