

























































































































































































































































































































// core
import Vue from "vue";
import printJs from "print-js";
import moment from "moment";
// service
import { batchService } from "@service/batch.service";
import { productService } from "@service/product.service";
import { masterTypeService } from "@service/master-type.service";
import localStorageService from "@/services/localStorage.service";
// interface
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { ResponseBatch, ResponseListBatch } from "@interface/batch.interface";
import { ResponsePagination } from "@/models/interface/common.interface";
import { ResponseListMasterType } from "@interface/master.interface";
import { IAuthorities } from "@interface/auth.interface";
// constant
import { DEFAULT_DATE_FORMAT } from "@/models/constant/date.constant";
// utility
import { debounceProcess } from "@/helpers/debounce";
import { IOption } from "@/models/interface-v2/common.interface";
import { mapGetters } from "vuex";
import { DEFAULT_PAGE_SIZE } from "@/models/constant/global.constant";
import MNotificationVue from "@/mixins/MNotification.vue";

export default Vue.extend({
  name: "ListQR",
  mixins: [
    MNotificationVue,
  ],
  data() {
    this.getProducts = debounceProcess(this.getProducts, 300);
    this.fillOptBarcodeNumber = debounceProcess(this.fillOptBarcodeNumber, 300);
    return {
      DEFAULT_DATE_FORMAT,
      loader: {
        list: false,
        productCode: false,
        status: false,
        initialProcess: false,
        barcodeNumber: false,
        download: false,
        print: false,
      },
      dtListBatch: {} as ResponseListBatch,
      batchParam: {
        limit: 20,
        page: 0,
        search: "",
        sorts: "createdDate:desc"
      },
      formModel: {
        barcodeNumber: undefined as string | undefined,
        productCode: undefined as string | undefined,
        productName: undefined as string | undefined,
        initialProcess: undefined as string | undefined,
        batchNumber: undefined as string | undefined,
        status: undefined as string | undefined,
        createdDate: undefined as string[] | undefined
      },
      formProps: {
        barcodeNumber: {
          label: "lbl_barcode_number",
          name: "barcode number",
          placeholder: "lbl_type_here"
        },
        productCode: {
          label: "lbl_product_code",
          name: "product code",
          placeholder: "lbl_choose"
        },
        productName: {
          label: "lbl_product_name",
          name: "product name",
          placeholder: "lbl_choose"
        },
        initialProcess: {
          label: "lbl_initial_process",
          name: "initial process",
          placeholder: "lbl_choose"
        },
        batchNumber: {
          label: "lbl_batch_number",
          name: "batch number",
          placeholder: "lbl_type_here"
        },
        status: {
          label: "lbl_status",
          name: "status",
          placeholder: "lbl_choose"
        },
        createdDate: {
          label: "lbl_created_date",
          name: "created date",
          placeholder: "lbl_choose"
        }
      },
      dtOptions: {
        barcodeNumber: {},
        productCode: {},
        productName: {},
        initialProcess: [] as IOption[],
        batchNumber: {},
        status: []
      },
      dtListStatus: [] as ResponseListMasterType[],
      userPrivileges: [] as IAuthorities[],
      selectedRowKeys: [] as string[]
    };
  },
  computed: {
    ...mapGetters({
      authUserPrivilege: "authStore/GET_USER_PRIVILEGES",
    }),
    hasPrivilegeCreate(): boolean {
      return this.authUserPrivilege.find(x => x.key === "batch" && x.privilege.update && x.privilege.create);
    },
    formWrapper() {
      return {
        labelCol: {
          sm: {
            span: 24
          },
          md: {
            span: 6
          }
        },
        wrapperCol: {
          sm: {
            span: 24
          },
          md: {
            span: 12
          }
        }
      };
    },
    hasPrivilegeGRWarehouse(): boolean {
      return this.userPrivileges.find(x => x.key === "good-receipt-warehouse")?.key === "good-receipt-warehouse";
    },
    hasPrivilegeInventoryReceive(): boolean | undefined {
      return this.userPrivileges.find(x => x.key === "inventory-receive")?.key === "inventory-receive";
    },
  },
  mounted() {
    this.getUserPrivileges();
    this.fillTable(this.batchParam, true);
    this.getProducts("", "all");
    this.getListStatus();
    this.getListInitialProcess();
  },
  methods: {
    moment,
    createNew(): void {
      this.$router.push({ name: "qr.create" });
    },
    getListBatch(params: RequestQueryParamsModel): Promise<ResponseListBatch> {
      return batchService.listBatch(params);
    },
    downloadBatch(params: RequestQueryParamsModel): Promise<ArrayBuffer> {
      return batchService.downloadBatch(params);
    },
    getListMaster(name: string): Promise<ResponseListMasterType[]> {
      return masterTypeService.listMaster({ name });
    },
    getPrintFile(batchNumber: string): Promise<ArrayBuffer> {
      return batchService.getPrintFile(batchNumber);
    },
    /**
     * get all batch
     * @param params request query params
     * @param flag determine if response data should fill the options
     */
    async fillTable(params: RequestQueryParamsModel, flag = false): Promise<void> {
      try {
        this.loader.list = true;
        const res = await this.getListBatch(params);
        this.dtListBatch = res;
        if (flag) this.dtOptions.barcodeNumber = res;
        this.dtListBatch.data.forEach((item) => item.key = item.batchNumber);
      } catch (error) {
        this.$message.error(this.$t("notif_process_fail").toString());
      } finally {
        this.loader.list = false;
      }
    },
    async fillOptBarcodeNumber(search = ""): Promise<void> {
      try {
        this.loader.barcodeNumber = true;
        const param: RequestQueryParamsModel = {
          page: 0,
          limit: 10,
          sorts: "createdDate:desc",
        };
        const searchBy: string[] = ["barcodeNumber!null"];
        if (search) searchBy.push(`barcodeNumber~*${search}*`);
        param.search = searchBy.join("_AND_");
        const res = await this.getListBatch(param);
        this.dtOptions.barcodeNumber = res;
      } catch (error) {
        this.$message.error(this.$t("notif_process_fail").toString());
      } finally {
        this.loader.barcodeNumber = false;
      }
    },
    handleEdit(record: ResponseBatch): void {
      this.$router.push({ name: "qr.edit", params: { id: record.id } });
    },
    responsePageSizeChange(response: ResponsePagination): void {
      this.batchParam.limit = response.size;
      this.batchParam.page = 0;
      this.fillTable(this.batchParam);
    },
    responseCurrentPageChange(response: ResponsePagination): void {
      this.batchParam.page = response.page - 1;
      this.fillTable(this.batchParam);
    },
    resetFilter(): void {
      this.batchParam.search = "";
      this.batchParam.page = 0;
      for (const key in this.formModel) {
        if (Object.prototype.hasOwnProperty.call(this.formModel, key)) {
          this.formModel[key] = undefined;
        }
      }
      this.fillTable(this.batchParam);
    },
    handleFilter(): void {
      const form = {...this.formModel};
      this.batchParam.page = 0;
      let search: string[] = [];
      if (form.barcodeNumber) search.push(`barcodeNumber~*${form.barcodeNumber}*`);
      if (form.productCode) search.push(`product.code~*${form.productCode}*`);
      if (form.productName) search.push(`product.name~*${form.productName}*`);
      if (form.initialProcess) search.push(`initialProcess~${form.initialProcess}`);
      if (form.batchNumber) search.push(`batchNumber~*${form.batchNumber}*`);
      if (form.status) search.push(`status~${form.status.toUpperCase()}`);
      if (form.createdDate && form.createdDate.length) {
        const start = this.moment(form.createdDate[0]).set({hour: 0, minute: 0, second: 0}).utcOffset("+07").format();
        const end = this.moment(form.createdDate[1]).set({hour: 23, minute: 59, second: 59}).utcOffset("+07").format();
        search.push(`createdDate>=${start}_AND_createdDate<=${end}`);
      }
      this.batchParam.search = search.join("_AND_");
      this.fillTable(this.batchParam);
    },
    async getProducts(search = "", prop = "code" as "code" | "name" | "all"): Promise<void> {
      try {
        this.loader.productCode = true;
        const param: RequestQueryParamsModel = {
          limit: DEFAULT_PAGE_SIZE,
          sorts: "createdDate:desc",
          page: 0,
        };
        if (search) param.search = `${prop}~*${search}*`;
        const response = await productService.listProduct(param);
        if (prop === "code") this.dtOptions.productCode = response;
        if (prop === "name") this.dtOptions.productName = response;
        if (prop === "all") {
          this.dtOptions.productCode = response;
          this.dtOptions.productName = response;
        }

      } catch (error) {
        this.$message.error(this.$t("notif_process_fail").toString());
      } finally {
        this.loader.productCode = false;
      }
    },
    async getListStatus(): Promise<void> {
      try {
        this.loader.status = true;
        const res = await this.getListMaster("BATCH_STATUS");
        this.dtListStatus = res;
      } catch (error) {
        this.$message.error(this.$t("notif_process_fail").toString());
      } finally {
        this.loader.status = false;
      }
    },
    async getListInitialProcess(): Promise<void> {
      try {
        this.loader.initialProcess = true;
        const res = await this.getListMaster("BATCH_INITIAL_PROCESS");
        this.dtOptions.initialProcess = res.map(x => {
          return { key: x.value, value: x.value };
        });
      } catch (error) {
        this.$message.error(this.$t("notif_process_fail").toString());
      } finally {
        this.loader.initialProcess = false;
      }
    },
    getUserPrivileges(): void {
      this.userPrivileges = localStorageService.loadUserPrivilege();
    },
    async handleDownload(): Promise<void> {
      try {
        this.loader.download = true;
        const param: RequestQueryParamsModel = {...this.batchParam};
        param.limit = this.dtListBatch.totalElements;
        const arrb = await this.downloadBatch(param);
        const url = window.URL.createObjectURL(new Blob([arrb]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "list_QR.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        this.$message.error(this.$t("notif_process_fail").toString());
      } finally {
        this.loader.download = false;
      }
    },
    async handlePrint(): Promise<void> {
      try {
        this.loader.print = true;
        if (this.selectedRowKeys.length) {
          const batchNumbers: string = this.selectedRowKeys.join(",");
          const file = await this.getPrintFile(batchNumbers);
          const pdf = window.URL.createObjectURL(new Blob([file]));
          printJs({
            printable: pdf,
            onError: (error) => {
              this.$notification.error({
                message: this.$t("lbl_error_title").toString(),
                description: error
              });
            }
          });
        } else {
          this.$notification.warning({
            message: this.$t("lbl_warning_title").toString(),
            description: this.$t("notif_choose_row").toString()
          });
        }
      } catch (error) {
        this.showErrorMessage("error_print_fail");
      } finally {
        this.loader.print = false;
      }
    },
    onSelectChange(selectedRowKeys: string[]): void {
      this.selectedRowKeys =  selectedRowKeys;
    }
  },
});
